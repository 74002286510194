<template>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand to="/">cobra</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/" exact exact-active-class="active">Home</b-nav-item>
            <b-nav-item to="/about" exact exact-active-class="active">About</b-nav-item>
            <b-nav-item to="/floor-price" exact exact-active-class="active">Floor price</b-nav-item>
            <b-nav-item to="/total-volume" exact exact-active-class="active">Total volume</b-nav-item>
            <b-nav-item href="#">Today</b-nav-item>
            <b-nav-item href="#">All Time</b-nav-item>
            <b-nav-item href="#">New</b-nav-item>
            <b-nav-item href="#">Market Cap</b-nav-item> 
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-select v-model="selectedChain" :options="chains"></b-form-select>
              <!-- <b-form-input size="sm" class="mr-sm-2" placeholder="Search for collection..."></b-form-input> -->
              <!-- <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button> -->
            </b-nav-form>

            <b-nav-item-dropdown right v-if="user.loggedIn">
            <template #button-content>{{user.data.email}}</template>
              <div v-if="user.loggedIn">
                <b-dropdown-item href="#">Profile</b-dropdown-item>
                <b-dropdown-item href="#">Sign Out</b-dropdown-item>
              </div>
            </b-nav-item-dropdown>
            <template v-else>
              <b-nav-item to="/sign-in" exact exact-active-class="active">Sign In</b-nav-item>
            </template>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data() {
    return {
      'selectedChain': null,
      'chains': [
        {"value": null, text: 'All chains'},
        {"value": 'ETHEREUM', text: 'Ethereum'},
        {"value": 'SOLANA', text: 'Solana'}
      ]
    }
  },
}
</script>