import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import { initializeApp } from "firebase/app"

import Cryptoicon from 'vue-cryptoicon'
import icon from 'vue-cryptoicon/src/icons'
Cryptoicon.add(icon)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "bootswatch/dist/darkly/bootstrap.min.css"
import 'animate.css'
import router from './router'
import store from "./store"
import { getAuth } from "firebase/auth"

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Cryptoicon)

const firebaseConfig = {
  apiKey: "AIzaSyDfpWCAdsOdEbS-R2GWAVKSNkixYvEUw_0",
  authDomain: "cobra-5630d.firebaseapp.com",
  projectId: "cobra-5630d",
  storageBucket: "cobra-5630d.appspot.com",
  messagingSenderId: "861810147626",
  appId: "1:861810147626:web:a4d3bb49ded121f6b59e53",
  measurementId: "G-R3ZFD78SV5"
}

initializeApp(firebaseConfig)
getAuth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
