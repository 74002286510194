<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">Sign in</div>
        <div class="card-body">
          <div v-if="error" class="alert alert-danger">{{error}}</div>
          <div v-if="loading"><b-spinner label="Loading..."></b-spinner></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

export default {
  data() {
    return {
      error: null,
      loading: true
    };
  },
  mounted() {
      const auth = getAuth()

      if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            console.log(result)
            window.localStorage.removeItem('emailForSignIn');
            this.$router.replace({ name: "Home" });
          })
          .catch((error) => {
            this.loading = false
            this.error = error.message
          });
      }
  }
};
</script>