<template>
    <b-card
        :img-src="collection.icon" 
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 15rem;"
        class="mb-2"
    >
        <template #header>
                <h6 class="mb-0">
                    <router-link :to="{ name: 'CollectionDetails', params: { id: collection.id, slug: collection.slug }}">
                        <strong>{{ collection.name }}</strong>
                    </router-link>
                    &nbsp;
                    <VoteCount compact="true" :collection="collection" />
                </h6>
        </template>
        <b-card-text>
            <span>Floor price <b-badge>{{ collection.stats.floorPrice }}</b-badge><br /></span>
            <span>Number of owners <b-badge>{{ collection.stats.numOwners }}</b-badge><br /></span>
            <span>Today votes <b-badge>{{ collection.stats.todayVotes }}</b-badge><br /></span>
        </b-card-text>
        <Vote :collection="collection" />
    </b-card>
</template>

<script>
import VoteCount from './VoteCount.vue'
import Vote from './Vote.vue'

export default {
    props: ['collection'],
    components: {
        VoteCount,
        Vote
    }
}
</script>