<template>
    <span v-if="compact">
        <span v-if="this.animate">
            <b-badge class="animate__heartBeat animate__animated" variant="success"><b-icon icon="heart-fill"></b-icon> {{ collection.stats.votes }}</b-badge>
        </span>
        <span v-else>
            <b-badge variant="warning"><b-icon icon="heart-fill"></b-icon> {{ collection.stats.votes }}</b-badge>
        </span>
    </span>
    <span v-else>
        <span v-if="this.animate">
            <h4 class="animate__heartBeat animate__animated"><b-badge variant="success"><b-icon icon="heart-fill"></b-icon> {{ collection.stats.votes }}</b-badge></h4>
        </span>
        <span v-else>
            <h4><b-badge variant="warning"><b-icon icon="heart-fill"></b-icon> {{ collection.stats.votes }}</b-badge></h4>
        </span>
    </span>
</template>

<script>
export default {
    props: ['collection', 'compact'],
    data() {
        return {
            animate: false
        }
    },
    mounted() {
        this.$root.$on('voted', (collectionId) => {
            if (this.collection.id == collectionId) {
                this.collection.stats.votes++

                this.animate = false
                this.animate = true
            }
        })
    }
}
</script>