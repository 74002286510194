<template>
    <div>
        <section v-if="errored">
            <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
        </section>
        <b-container class="collection-details" v-if="item.name">
            <b-row>
                <b-col>
                    <h1>{{ item.name }}</h1>
                    <p class="mb-0">
                        {{ item.description }}
                    </p>
                    <p></p>
                    <p>
                        <strong>URL</strong>: <a :href="item.url">{{ item.url }}</a>
                    </p>
                    <p v-if="item.chain == 'ETHEREUM'">
                        <b-button :href="'https://opensea.io/collection/' + item.slug" block variant="success">Buy on Opensea.io</b-button>
                    </p>
                    <p v-if="item.chain == 'SOLANA'">
                        <b-button :href="'https://solanart.io/collections/' + item.slug" block variant="success">Buy on Solanart.io</b-button>
                    </p>
                </b-col>
                <b-col cols="3" class="icon-row">
                    <div>
                        <b-card
                            :title="item.name"
                            :img-src="item.icon"
                            img-alt="Image"
                            img-top
                            tag="article"
                            style="max-width: 20rem;"
                            class="mb-2"
                        >
                            <VoteCount :collection="item" />
 
                            <b-card-text>
                                <span>Floor price <b-badge>{{ item.stats.floorPrice }}</b-badge><br /></span>
                                <span>Number of owners <b-badge>{{ item.stats.numOwners }}</b-badge><br /></span>
                                <span>Total supply <b-badge>{{ item.stats.totalSupply }}</b-badge><br /></span>
                                <span>Today votes <b-badge>{{ item.stats.todayVotes }}</b-badge><br /></span>
                            </b-card-text>

                            <Vote :collection="item" />
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div v-if="loading"><b-spinner label="Loading..."></b-spinner></div>
    </div>
</template>

<script>
import axios from 'axios'
import Vote from './Vote.vue'
import VoteCount from './VoteCount.vue'

export default {
    data() {
        return {
            item: {
                stats: {}
            },
            loading: true,
            errored: false
        }
    },
    components: {
        Vote,
        VoteCount
    },
    mounted() {
        axios
            .get(process.env.VUE_APP_COBRA_URL + '/v1/collections/' + this.$route.params.id)
            .then(response => {
                console.log(response)
                this.item = response.data
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false)
    }
}
</script>

<style scoped>
    .icon-row {
        text-align: center;
    }

    .collection-details {
        background-color: #2b2b2b;
        padding: 40px;
        border-radius: 5px;
    }
</style>