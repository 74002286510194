<template>
  <div>
    <Collection />
  </div>
</template>

<script>
import Collection from '@/components/Collection.vue'

export default {
  name: 'CollectionDetails',
  components: {
    Collection
  }
}
</script>
