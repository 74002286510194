<template>
  <div>
    <section v-if="errored">
        <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>

    <section v-else>
        <template v-if="mode == 'promoted'">
            <b-container>
            <b-row class="justify-content-md-center">
                <b-col cols="3" v-for="item in items" :key="item.id" >
                    <CollectionCard :collection="item" />
                </b-col>
            </b-row>
            </b-container>
        </template>
        <template v-else>
            <b-table striped hover :fields="fields" :items="items">
                <template #cell(name)="data">
                    <router-link :to="{ name: 'CollectionDetails', params: { id: data.item.id, slug: data.item.slug }}"><b-avatar variant="info" :src="data.item.icon"></b-avatar>    <strong>{{ data.item.name }}</strong></router-link>
                </template>
                <template #cell(chain)="data">
                    <span class="crypto-text-chain"><cryptoicon symbol="eth" size="24" color="white" /> {{ data.item.chain }}</span>
                </template>
                <template #cell(floorPrice)="data">
                    <span class="crypto-text-price"><cryptoicon symbol="eth" size="16" color="white" /> {{ data.item.stats.floorPrice }}</span>
                </template>
                <template #cell(numberOfOwners)="data">
                    {{ data.item.stats.numOwners }}
                </template>
                <template #cell(totalSupply)="data">
                    {{ data.item.stats.totalSupply }}
                </template>
                <template #cell(totalVolume)="data">
                    <span class="crypto-text-price"><cryptoicon symbol="eth" size="16" color="white" /> {{ data.item.stats.totalVolume }}</span>
                </template>
                <template #cell(votes)="data">
                    <VoteCount :collection="data.item" />
                </template>
                <template #cell(actions)="data">
                    <Vote :collection="data.item" />
                </template>
            </b-table>
        </template>
        <div v-if="loading"><b-spinner label="Loading..."></b-spinner></div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import Vote from './Vote.vue'
import VoteCount from './VoteCount.vue'
import CollectionCard from './CollectionCard.vue'

export default {
    props: ["search", "mode"],
    components: {
        Vote,
        VoteCount,
        CollectionCard
    },
    data() {
        return {
            fields: [
                "name",
                "chain",
                "numberOfOwners",
                "totalSupply",
                "floorPrice",
                "totalVolume",
                "votes",
                "actions",
            ],
            items: null,
            loading: true,
            errored: false,
            collectionIdToVote: null,
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_COBRA_URL + '/v1/collections' + this.search)
            .then(response => {
                console.log(response)
                this.items = response.data._embedded.collections
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false)
    }
}
</script>

<style scoped>
.crypto-text-price svg {
    vertical-align: sub;
}

.crypto-text-chain svg {
    vertical-align: bottom;
}
</style>

<style>
table td {
    vertical-align: baseline !important;
}
</style>