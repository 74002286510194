<template>
  <div id="app">
    <Navbar />
    <b-container class="app-content">
      <router-view/>
      <vue-hcaptcha ref="voteCaptcha" @verify="onCaptchaVerified" @closed="onCaptchaClosed" theme="dark" size="invisible" sitekey="51e41b04-de16-40e0-ac31-39197f6d2364"></vue-hcaptcha>
    </b-container>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'

export default {
  name: 'App',
  components: {
    Navbar,
    VueHcaptcha
  },
  mounted() {
    this.$root.$on('showCaptcha', () => {
        this.$refs.voteCaptcha.execute()
    })
  },
  methods: {
    onCaptchaVerified(token, eKey) {
      this.$root.$emit('captchaVerified', token, eKey)
    },
    onCaptchaClosed() {
      this.$root.$emit('captchaClosed')
    }
  }
}
</script>

<style scoped>
.app-content {
  margin-top: 20px;
}
</style>