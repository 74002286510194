<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">Sign In</div>
        <div class="card-body">
          <div v-if="error" class="alert alert-danger">{{error}}</div>
          <div v-if="loading"><b-spinner label="Loading..."></b-spinner></div>
          <b-alert variant="success" show v-if="success">Please check your inbox to finish the authentication process...</b-alert>
          <form action="#" @submit.prevent="submit" v-if="!loading && !success">
            <div class="form-group row">
              <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

              <div class="col-md-6">
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  name="email"
                  value
                  required
                  autofocus
                  v-model="form.email"
                />
              </div>
            </div>

            <div class="form-group row mb-0">
              <div class="col-md-8 offset-md-4">
                <button type="submit" class="btn btn-primary">Sign In</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

export default {
  data() {
    return {
      form: {
        email: "",
      },
      loading: false,
      error: null,
      success: false,
    };
  },
  methods: {
    submit() {
        this.loading = true
        const auth = getAuth()

        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: window.location.origin + '/sign-in/verify-email',
            // This must be true.
            handleCodeInApp: true,
        }

        sendSignInLinkToEmail(auth, this.form.email, actionCodeSettings)
        .then(() => {
            window.localStorage.setItem('emailForSignIn', this.form.email)
            this.loading = false
            this.success = true
        })
        .catch(err => {
          this.error = err.message
          this.loading = false
        });
    }
  }
};
</script>