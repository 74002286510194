<template>
  <div>
    <h2>Floor Price</h2>
    <Collections search="/search/findOrderByFloorPrice" />
  </div>
</template>

<script>
import Collections from '@/components/Collections.vue'

export default {
  name: 'Floor Price',
  components: {
    Collections
  }
}
</script>
