<template>
  <div>
    <h3>
      <b-icon icon="star-fill"></b-icon> Promoted
      <small class="text-muted">advertised collections</small>
    </h3>
    <Collections search="/search/findByIsPromotedTrue" mode="promoted" />
    <h3>
      <b-icon icon="award-fill"></b-icon> Trending Today
      <small class="text-muted">based on your votes</small>
    </h3>
    <Collections search="/search/findTrending" mode="normal" />
  </div>
</template>

<script>
import Collections from '@/components/Collections.vue'

export default {
  name: 'Home',
  components: {
    Collections
  }
}
</script>
