<template>
  <div>
    <h2>Total Volume</h2>
    <Collections search="/search/findOrderByTotalVolume" />
  </div>
</template>

<script>
import Collections from '@/components/Collections.vue'

export default {
  name: 'Total Volume',
  components: {
    Collections
  }
}
</script>
