<template>
    <b-button variant="success" disabled v-if="inProgress">
        <b-spinner small type="grow"></b-spinner>
        Voting...
    </b-button>
    <b-button v-else-if="alreadyVoted" v-b-popover.hover.top="'You already voted for this collection today.'" title="Check back tomorrow!">
        Vote
    </b-button>
    <b-button variant="success" @click.prevent="initVoting()" v-else>Vote</b-button>
</template>

<script>
import axios from 'axios'
import { getAuth } from "firebase/auth"
import ls from 'localstorage-slim'
import moment from 'moment'

export default {
    props: ['collection'],
    data() {
        return {
            inProgress: false,
            alreadyVoted: false
        }
    },
    methods: {
        initVoting() {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                this.authenticationVoteError()
                return
            }
            
            this.$root.$emit('showCaptcha')
            this.inProgress = true
        },
        authenticationVoteError() {
            this.$bvToast.toast('You need to be authenticated to vote for collections.', {
                title: 'Authentication Required!',
                variant: 'danger',
                toaster: 'b-toaster-bottom-center'
            })
        },
        voteError(error) {
            let message = error.message
            if (error.response && error.response.data) {
                message = error.response.data.message
            }

            this.$bvToast.toast(message, {
                title: 'Vote Failed!',
                variant: 'danger',
                toaster: 'b-toaster-bottom-center'
            })

            if (message == "You already voted for this collection today.") {
                this.setAlreadyVoted()
            }
        },
        sendVoteRequest(captchaChallenge, userToken) {
            axios
                .post(process.env.VUE_APP_COBRA_URL + '/v1/collections/' + this.collection.id + '/vote', {
                    captchaChallenge: captchaChallenge
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + userToken
                    }
                })
                .then(response => {
                    console.log(response)
                    this.setAlreadyVoted()
                    this.$root.$emit('voted', this.collection.id)
                })
                .catch(error => {
                    this.voteError(error)
                })
        },
        setAlreadyVoted() {
            let now = moment().utc(),
            tomorrow = moment().utc().add(1, 'day').startOf('day'),
            difference = moment.duration(tomorrow.diff(now))

            ls.set('voted_' + this.collection.id, true, { ttl: difference.asSeconds() });
            this.alreadyVoted = true
        }
    },
    mounted() {
        const alreadyVoteStorage = ls.get('voted_' + this.collection.id)
        if (alreadyVoteStorage) {
            this.alreadyVoted = alreadyVoteStorage
        }

        this.$root.$on("captchaVerified", (token, eKey) => {
            if (!this.inProgress) {
                return
            }

            const self = this;
            const auth = getAuth();
            const user = auth.currentUser;

            if (user) {
                user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    console.log(idToken)
                    self.sendVoteRequest(token, idToken)
                }).catch(function(error) {
                    console.log(error)
                    this.authenticationVoteError()
                })
            } else {
                this.authenticationVoteError()
            }

            this.inProgress = false
            console.log(eKey)
        })
        this.$root.$on("captchaClosed", () => {
            this.inProgress = false
        })
    }
}
</script>

<style>

</style>